import React, { FC } from "react"
import { PageProps } from "gatsby";
import { BaseLayout } from "@layouts";

type NotFountData = {}
type NotFountContext = {
    locale: string
}

const NotFount: FC<PageProps<NotFountData, NotFountContext>> = ( {} ) => {
    return (
        <BaseLayout>
            <h1>Not found</h1>
        </BaseLayout>
    )
}

export default NotFount
